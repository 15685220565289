<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="mt-4">
        <div v-if="$route.name !== 'Stats Timeslot Detail'"
          class="flex items-center justify-between w-72 mr-4">
          <label class="w-20 font-semibold">{{ $t('overview') }}</label>
          <t-select
            class="w-52 text-sm"
            :value="statFilter"
            :options="options"
            @change="onStatFilterChanged"
          ></t-select>
        </div>
        <router-view @setLoading="loading = $event"/>
      </div>
    </template>
  </layout>
</template>

<script>
import i18n from "@/i18n";
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    showFilter: true,
    options: [
      i18n.t('stats_filter_timeslot_option'),
      i18n.t('stats_filter_employee_option')
    ]
  }),
  methods: {
    onStatFilterChanged(filter) {
      console.log({ filter });
      if (filter === i18n.t('stats_filter_timeslot_option')) {
        this.$router.push('/stats/timeslot');
        return;
      }

      if (filter === i18n.t('stats_filter_employee_option')) {
        this.$router.push('/stats/employee');
        return;
      }
    }
  },
  computed: {
    statFilter() {
      return this.$route.name === 'Stats Timeslot'
        ? i18n.t('stats_filter_timeslot_option')
        : i18n.t('stats_filter_employee_option');
    }
  }
}
</script>
